import "./fightCard.scss";

import { useSelector } from "react-redux";
import { selectActiveEvent } from "../../store/app";
import { FightDTO } from "../../store/storeTypes";

const FightCard = (): JSX.Element | null => {
  const event = useSelector(selectActiveEvent);

  const getFightResult = (
    fighter: "fighter1" | "fighter2",
    result: FightDTO["result"]
  ): string => {
    if (result === "draw") {
      return "REMIS";
    } else if (result === "no_contest") {
      return "NO CONTEST";
    } else if (fighter === result) {
      return "WYGRANA";
    }
    return "PRZEGRANA";
  };

  const renderFightInfo = (
    title: string,
    fighter1Value?: string | number,
    fighter2Value?: string | number,
    unit?: string
  ): JSX.Element | null => {
    return fighter1Value || fighter2Value ? (
      <div className="info">
        <div className="info-data">
          {fighter1Value ? fighter1Value + (unit ? ` ${unit}` : "") : "-"}
        </div>
        <div className="info-description">{title}</div>
        <div className="info-data">
          {fighter2Value ? fighter2Value + (unit ? ` ${unit}` : "") : "-"}
        </div>
      </div>
    ) : null;
  };

  return event?.fightCard?.length ? (
    <div className="fight-card container">
      <h2 className="section-header">KARTA WALK</h2>
      <div className="fights-container">
        {event?.fightCard.map((fight, index) => (
          <div className="fight-container" key={index}>
            <div className="fight-number">
              WALKA {(event?.fightCard?.length || 0) - index}
            </div>
            <div className="fighter text-right">
              <div className="fighter-name">{fight.fighter1Name}</div>
              <div className="fighter-subname">{fight?.fighter1Subname}</div>
            </div>
            <div className="versus">VS</div>
            <div className="fighter">
              <div className="fighter-name">{fight.fighter2Name}</div>
              <div className="fighter-subname">{fight?.fighter2Subname}</div>
            </div>
            <div className="fight-info">
              {renderFightInfo("WIEK", fight?.fighter1Age, fight?.fighter2Age)}
              {renderFightInfo(
                "WZROST",
                fight?.fighter1Height,
                fight?.fighter2Height,
                "CM"
              )}
              {renderFightInfo(
                "WAGA",
                fight?.fighter1Weight,
                fight?.fighter2Weight,
                "KG"
              )}
              {renderFightInfo(
                "ZASIĘG",
                fight?.fighter1Reach,
                fight?.fighter2Reach,
                "CM"
              )}
              {renderFightInfo(
                "REKORD",
                fight?.fighter1Record,
                fight?.fighter2Record
              )}
              {fight?.result &&
                renderFightInfo(
                  "WYNIK",
                  getFightResult("fighter1", fight.result),
                  getFightResult("fighter2", fight.result)
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default FightCard;
