import moment from "moment";
import "moment/locale/pl";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Loader from "./components/Loader/Loader";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import EventPage from "./pages/EventPage/EventPage";
import HomePage from "./pages/HomePage/HomePage";

const App = () => {
  moment.locale("pl");

  return (
    <BrowserRouter>
      <Loader />
      <ScrollToTop />
      <Header />
      <main className="main-container">
        <Routes>
          <Route path="" element={<HomePage />} />
          <Route path="/event/:id" element={<EventPage />} />
          <Route path="*" element={<Navigate to="" />} />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
