import "./mainBanner.scss";

import banner from "../../assets/main_banner.webp";

const MainBanner = () => {
  return (
    <div className="banner container">
      <img src={banner} alt="banner" className="banner-img center"></img>
      <h1 className="title">KIEDY KOLEJNA GALA?</h1>
      <h2 className="subtitle">
        SPRAWDŹ DATY KONFERENCJI, FACE2FACE ORAZ GŁÓWNYCH WYDARZEŃ
      </h2>
    </div>
  );
};

export default MainBanner;
