import "./loader.scss";
import logo from "../../assets/freakday_logo_icon.svg";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../store/app";

const Loader = () => {
  const isLoading = useSelector(selectIsLoading);

  return (
    <div className={`loader-container ${isLoading ? "" : "loader-loaded"}`}>
      <img src={logo} alt="logo" className="logo" width="86px" />
    </div>
  );
};

export default Loader;
