import "./header.scss";
import logo from "../../assets/freakday_logo_color.svg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/fontawesome-free-brands";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Header = () => {
  return (
    <header className="header">
      <div className="container header-inner">
        <Link to="">
          <img src={logo} alt="logo" className="logo" />
        </Link>
        <a
          className="instagram-link"
          href="https://www.instagram.com/freakday.pl/"
          target="_blank"
          rel="noreferrer"
          aria-label="Instagram"
        >
          <span className="subscribe-us">Obserwuj nas!</span>
          <FontAwesomeIcon className="icon" icon={faInstagram as IconProp} />
        </a>
      </div>
    </header>
  );
};

export default Header;
