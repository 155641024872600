import "./calendar.scss";

import { selectEvents } from "../../store/app";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment, { Moment } from "moment";
import { CalendarDay, DAYS, DAYS_IN_VIEW, MONTHS } from "./calendarTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Event } from "../../store/storeTypes";
import { Link } from "react-router-dom";

const Calendar = (): JSX.Element | null => {
  const [selectedDate, setSelectedDate] = useState<Moment>(
    moment().startOf("month")
  );
  const [calendarDays, setCalendarDays] = useState<CalendarDay[]>([]);
  const events = useSelector(selectEvents);

  useEffect(() => {
    const calendarDays: CalendarDay[] = [];

    const startDate = moment(selectedDate).subtract(
      (selectedDate.day() || 7) - 1,
      "day"
    );

    for (let i = 0; i < DAYS_IN_VIEW; i++) {
      calendarDays.push({ date: moment(startDate).add(i, "day") });
    }

    const filteredEvents = events.filter(
      ({ date }) =>
        moment(date).isSameOrAfter(startDate) &&
        moment(date).isBefore(moment(startDate).add(DAYS_IN_VIEW, "day"))
    );

    filteredEvents.forEach((event) => {
      const index = moment(event.date).diff(startDate, "day");
      calendarDays[index].event = event;
    });

    setCalendarDays(calendarDays);
  }, [events, selectedDate]);

  const renderDay = (date: moment.Moment, event?: Event): JSX.Element => {
    return (
      <>
        <div
          className={`
            day-indicator
            ${moment(date).isSame(moment(), "date") ? "primary" : ""}
          `}
        >
          {date.format("DD")}
        </div>
        <div className={`day-info ${event?.isMainEvent ? "primary" : ""}`}>
          {event?.name}
        </div>
        {event?.federation?.logo && (
          <img
            className={`
              event-logo
              ${event?.isMainEvent || event?.redirectLink ? "primary" : ""}
            `}
            src={event?.federation?.logo}
            alt="federation logo"
          />
        )}
      </>
    );
  };

  const renderCalendarDay = (
    date: moment.Moment,
    event?: Event
  ): JSX.Element => {
    return event?.isMainEvent ? (
      <Link
        className={`
          calendar-day
          ${!selectedDate.isSame(date, "month") ? "other-month" : ""}
        `}
        key={date.format("DD-MM-YYYY")}
        to={`/event/${event.id}`}
      >
        {renderDay(date, event)}
      </Link>
    ) : event?.redirectLink ? (
      <a
        className={`
          calendar-day
          ${!selectedDate.isSame(date, "month") ? "other-month" : ""}
        `}
        key={date.format("DD-MM-YYYY")}
        href={event.redirectLink}
        target="_blank"
        rel="noreferrer"
      >
        {renderDay(date, event)}
      </a>
    ) : (
      <div
        className={`
          calendar-day
          ${!selectedDate.isSame(date, "month") ? "other-month" : ""}
        `}
        key={date.format("DD-MM-YYYY")}
      >
        {renderDay(date, event)}
      </div>
    );
  };

  return events.length ? (
    <div className="calendar container">
      <h2 className="section-header">KALENDARZ WYDARZEŃ</h2>
      <div className="calendar-header">
        <button
          className="arrow-btn"
          onClick={() =>
            setSelectedDate(moment(selectedDate.subtract(1, "month")))
          }
          aria-label="Previous Month"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button
          className="arrow-btn"
          onClick={() => setSelectedDate(moment(selectedDate.add(1, "month")))}
          aria-label="Next Month"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
        <button
          className="today-btn"
          onClick={() => setSelectedDate(moment(moment().startOf("month")))}
          aria-label="Current Month"
        >
          DZISIAJ
        </button>
        <div className="date">
          {MONTHS[selectedDate.get("month")]} {selectedDate.get("year")}
        </div>
      </div>
      <div className="days-header">
        {DAYS.map((day) => (
          <div key={day}>
            <span className="day-full">{day}</span>
            <span className="day-shortcut">{day.charAt(0)}</span>
          </div>
        ))}
      </div>
      <div className="calendar-view">
        {calendarDays.map(({ date, event }) => {
          return renderCalendarDay(date, event);
        })}
      </div>
    </div>
  ) : null;
};

export default Calendar;
