/* eslint-disable react-hooks/exhaustive-deps */
import "swiper/css";
import "swiper/css/grid";
import "./timeline.scss";

import { Swiper as SwiperContainer, SwiperSlide } from "swiper/react";
import Swiper, { Grid } from "swiper";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectEvents } from "../../store/app";
import { Link } from "react-router-dom";

const Timeline = (): JSX.Element | null => {
  const [swiper, setSwiper] = useState<Swiper>();

  const events = useSelector(selectEvents);

  const centerToPrimarySlide = (): void => {
    const width = window.innerWidth;
    const activeEventIndex = events.findIndex(({ isPrimary }) => isPrimary);
    let shift = 0;

    if (width < 576) {
      shift = 0;
    } else if (width >= 576 && width < 992) {
      shift = 1;
    } else if (width >= 992 && width < 1400) {
      shift = 2;
    } else {
      shift = 3;
    }

    swiper?.slideTo(Math.floor(activeEventIndex / 2) - shift);
  };

  useEffect(() => {
    centerToPrimarySlide();
  }, [swiper, events]);

  return events.length ? (
    <div className="timeline container">
      <SwiperContainer
        slidesPerView={2}
        grid={{
          rows: 2,
        }}
        spaceBetween={40}
        breakpoints={{
          576: {
            slidesPerView: 3,
            grid: { rows: 2 },
          },
          768: {
            slidesPerView: 4,
            grid: { rows: 2 },
          },
          992: {
            slidesPerView: 5,
            grid: { rows: 2 },
          },
          1400: {
            slidesPerView: 6,
            grid: { rows: 2 },
          },
        }}
        modules={[Grid]}
        className={`
          events-timeline
          ${events.length % 2 === 0 ? "extended-timeline" : ""}
        `}
        onSwiper={setSwiper}
      >
        {events.map((event) => (
          <SwiperSlide
            key={event.id}
            className={`
              ${event.isMainEvent ? "main-event" : ""}
              ${event.isExpired ? "expired-event" : ""}
              ${event?.isPrimary ? "primary-event" : ""}
            `}
          >
            <span className="confederation-name">{event.federation.name}</span>
            <br />
            <span className="event-date">
              {`${moment(event.date).format("DD.MM.YYYY")}${
                event?.hour ? ` | ${event.hour}` : ""
              }`}
            </span>
            <br />
            {event?.isMainEvent ? (
              <Link className="event-name" to={`/event/${event.id}`}>
                {event.name}
              </Link>
            ) : event?.redirectLink ? (
              <a
                className="event-name"
                href={event.redirectLink}
                target="_blank"
                rel="noreferrer"
              >
                {event.name}
              </a>
            ) : (
              <span className="event-name">{event.name}</span>
            )}
          </SwiperSlide>
        ))}
      </SwiperContainer>
    </div>
  ) : null;
};

export default Timeline;
