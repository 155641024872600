import "./federationsList.scss";
import "swiper/css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useSelector } from "react-redux";
import { selectFederations } from "../../store/app";

const FederationsList = (): JSX.Element | null => {
  const federations = useSelector(selectFederations);

  return federations.length ? (
    <div className="federations-list container">
      <Swiper
        slidesPerView={3}
        spaceBetween={16}
        allowTouchMove={false}
        loop={true}
        autoplay={{
          delay: 2000,
        }}
        breakpoints={{
          576: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 6,
          },
          992: {
            slidesPerView: 8,
            spaceBetween: 20,
          },
        }}
        modules={[Autoplay]}
        className="federations-carousel"
      >
        {federations.map(({ id, name, logo }) => (
          <SwiperSlide key={id}>
            <div className="federation">
              <img src={logo} alt={name} className="federation-logo" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : null;
};

export default FederationsList;
