import "./eventBanner.scss";

import eventBanner from "../../assets/event_banner.webp";
import { selectActiveEvent } from "../../store/app";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTicket, faTv } from "@fortawesome/free-solid-svg-icons";

type Countdown = {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
};

const EventBanner = (): JSX.Element | null => {
  const event = useSelector(selectActiveEvent);
  const [countdown, setCountdown] = useState<Countdown>({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    if (event) {
      const eventDate = moment(
        `${event.date} ${event?.hour !== null ? event.hour : "20:00"}`
      );

      let diff = eventDate.diff(moment(), "second");

      if (diff > 0) {
        const interval = setInterval(() => {
          diff = eventDate.diff(moment(), "second");

          setCountdown({
            days:
              Math.floor(diff / (60 * 60 * 24)) >= 10
                ? Math.floor(diff / (60 * 60 * 24)).toString()
                : "0" + Math.floor(diff / (60 * 60 * 24)),
            hours: (
              "0" + Math.floor((diff % (60 * 60 * 24)) / (60 * 60))
            ).slice(-2),
            minutes: ("0" + Math.floor((diff % (60 * 60)) / 60)).slice(-2),
            seconds: ("0" + Math.floor(diff % 60)).slice(-2),
          });
        }, 1000);

        return () => clearInterval(interval);
      }
    }
  }, [event]);

  const getEventTimeInfo = (): JSX.Element | null => {
    if (event) {
      const eventDate = moment(
        `${event.date} ${event?.hour !== null ? event.hour : "20:00"}`
      );

      let diff = eventDate.diff(moment(), "second");

      if (diff > 0) {
        return (
          <div className="countdown">
            <div className="countdown-element">
              <span className="countdown-value">{countdown?.days}</span>
              <span className="countdown-info">Dni</span>
            </div>
            <div className="countdown-element">
              <span className="countdown-value">{countdown?.hours}</span>
              <span className="countdown-info">Godzin</span>
            </div>
            <div className="countdown-element">
              <span className="countdown-value">{countdown?.minutes}</span>
              <span className="countdown-info">Minut</span>
            </div>
            <div className="countdown-element">
              <span className="countdown-value">{countdown?.seconds}</span>
              <span className="countdown-info">Sekund</span>
            </div>
          </div>
        );
      }

      diff = eventDate.diff(moment(), "hours");

      if (diff > -5) {
        return <div className="event-time-info">Wydarzenie właśnie trwa</div>;
      }

      return <div className="event-time-info">Wydarzenie się zakończyło</div>;
    }

    return null;
  };

  return event ? (
    <div className="event-banner container">
      <img src={eventBanner} alt="banner" className="banner-img"></img>
      <img
        src={event?.federation.logo}
        alt="federation-logo"
        className="federation-logo center"
      />
      <h1 className="title">{event?.name}</h1>
      {getEventTimeInfo()}
      <div className="event-info">
        <div className="info">
          <span>Data:</span> <br />
          <span className="font-weight-bold">
            {moment(event?.date).format("DD.MM.YYYY")}
          </span>
        </div>
        <div className="info">
          <span>Godzina:</span> <br />
          <span className="font-weight-bold">{event?.hour || "-"}</span>
        </div>
        <div className="info">
          <span>Miejsce:</span> <br />
          <span className="font-weight-bold">{event?.place || "-"}</span>
        </div>
      </div>
      <div className="buttons-container">
        {event?.ticketLink && (
          <a
            className="action-button"
            href={event.ticketLink}
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-container">
              <FontAwesomeIcon className="icon" icon={faTicket} />
            </div>
            <span>KUP BILET</span>
          </a>
        )}
        {event?.ppvLink && (
          <a
            className="action-button"
            href={event.ppvLink}
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-container">
              <FontAwesomeIcon className="icon" icon={faTv} />
            </div>
            <span>KUP PPV</span>
          </a>
        )}
      </div>
    </div>
  ) : null;
};

export default EventBanner;
