import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "./news.scss";

import { selectArticles } from "../../store/app";
import { useSelector } from "react-redux";
import moment from "moment";
import { Swiper as SwiperContainer, SwiperSlide } from "swiper/react";
import { Grid, Pagination } from "swiper";

const News = (): JSX.Element | null => {
  const articles = useSelector(selectArticles);

  return articles?.length ? (
    <div className="news container">
      <h2 className="section-header">HOT NEWS</h2>
      <SwiperContainer
        slidesPerView={1}
        grid={{
          rows: 4,
        }}
        breakpoints={{
          768: {
            slidesPerView: 2,
            grid: { rows: 2 },
          },
          1200: {
            slidesPerView: 3,
            grid: { rows: 2 },
          },
        }}
        spaceBetween={18}
        pagination={true}
        modules={[Grid, Pagination]}
        className={`news-timeline`}
      >
        {articles.map((article) => (
          <SwiperSlide key={article.id}>
            <article
              className="article-container"
              itemScope
              itemType="http://schema.org/Article"
            >
              <meta itemProp="datePublished" content={article.date}></meta>
              <meta itemProp="author" content="Freakday.pl"></meta>
              <img itemProp="image" src={article.image} alt={article.title} />
              <div className="article-details">
                <h2 itemProp="name headline" className="article-title">
                  {article.title}
                </h2>
                <div className="article-info">
                  <span className="article-date">
                    {moment(article.date).format("DD.MM.YYYY")}
                  </span>
                  <a
                    className="article-ref"
                    href={article.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Źródło
                  </a>
                </div>
              </div>
            </article>
          </SwiperSlide>
        ))}
      </SwiperContainer>
    </div>
  ) : null;
};

export default News;
