import "./mainEvents.scss";

import { selectEvents } from "../../store/app";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Event } from "../../store/storeTypes";
import { Link } from "react-router-dom";

const MainEvents = (): JSX.Element | null => {
  const [mainEvents, setMainEvents] = useState<Event[]>([]);
  const events = useSelector(selectEvents);

  useEffect(() => {
    let mainEvents = events.filter(
      ({ isMainEvent, isExpired }) => isMainEvent && !isExpired
    );

    if (mainEvents.length > 8) {
      mainEvents.length = 8;
    } else if (mainEvents.length < 3) {
      mainEvents = [];
    }

    setMainEvents(mainEvents);
  }, [events]);

  return mainEvents.length ? (
    <div className="main-events container">
      <h2 className="section-header">GŁÓWNE WYDARZENIA</h2>
      <div
        className={`inner-container ${mainEvents.length > 3 ? "extended" : ""}`}
      >
        {mainEvents.map((event) => (
          <Link className="event-card" key={event.id} to={`/event/${event.id}`}>
            <div className="logo-container">
              <img
                className="event-logo"
                src={event.federation.logo}
                alt={event.federation.name}
              />
            </div>
            <div className="data-container">
              <span className="event-name font-weight-bold">{event.name}</span>
              <span>Data:</span>
              <span className="event-date font-weight-bold">
                {`${moment(event.date).format("DD.MM.YYYY")}${
                  event?.hour ? ` | ${event.hour}` : ""
                }`}
              </span>
              <span>Miejsce:</span>
              <span className="font-weight-bold">{event?.place || "-"}</span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  ) : null;
};

export default MainEvents;
