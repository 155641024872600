import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { isExpired } from "../utils/dateComparison";
import { prepr } from "../utils/prepr";
import {
  AppState,
  Article,
  ArticleDTO,
  Event,
  EventDTO,
  Federation,
  FederationDTO,
  GraphQLArrayResponse,
  GraphQLElementResponse,
  StoreType,
} from "./storeTypes";

const initialState: AppState = {
  federations: [],
  events: [],
  articles: [],
  isLoading: true,
  fetchCount: 0,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setFederations: (state, action: PayloadAction<Federation[]>) => {
      state.federations = action.payload;
    },
    setEvents: (state, action: PayloadAction<Event[]>) => {
      state.events = action.payload;
    },
    setActiveEvent: (state, action: PayloadAction<Event>) => {
      state.activeEvent = action.payload;
    },
    setArticles: (state, action: PayloadAction<Article[]>) => {
      state.articles = action.payload;
    },
    fetchStart: (state) => {
      state.fetchCount += 1;
      state.isLoading = state.fetchCount > 0;
    },
    fetchEnd: (state) => {
      state.fetchCount -= 1;
      state.isLoading = state.fetchCount > 0;
    },
  },
});

export const {
  setFederations,
  setEvents,
  setActiveEvent,
  setArticles,
  fetchStart,
  fetchEnd,
} = appSlice.actions;

export const getFederations =
  () => async (dispatch: Dispatch<PayloadAction<Federation[] | undefined>>) => {
    dispatch(fetchStart());

    const data: GraphQLArrayResponse<FederationDTO> = await prepr
      .graphqlQuery(
        `{
          data: Federations ( limit : 250 ) {
            items {
              id: _id
              name
              logo {
                url
              }
            }
          }
        }`
      )
      .fetch();

    if (data?.data) {
      const federations: Federation[] = data?.data?.data?.items?.map(
        (federation) => {
          return {
            ...federation,
            logo: federation.logo[0].url,
          };
        }
      );

      dispatch(setFederations(federations));
    } else {
      console.error(`ERROR: ${data?.code}-${data?.message}`);
    }

    dispatch(fetchEnd());
  };

export const getEvents =
  () => async (dispatch: Dispatch<PayloadAction<Event[] | undefined>>) => {
    dispatch(fetchStart());

    const data: GraphQLArrayResponse<EventDTO> = await prepr
      .graphqlQuery(
        `{
          data: Events( limit : 100, sort : date_DESC ) {
            items {
              id : _id
              federation {
                id : _id
                name
                logo {
                  url
                }
              }
              name
              date
              hour
              isMainEvent : is_main_event
              place
              ticketLink : ticket_link
              ppvLink : ppv_link
              redirectLink : redirect_link
              fightCard: fight_card {
                ... on Fight {
                  id : _id
                  fighter1_name
                  fighter1_subname
                  fighter1_age
                  fighter1_height
                  fighter1_weight
                  fighter1_reach
                  fighter1_record
                  fighter2_name
                  fighter2_subname
                  fighter2_age
                  fighter2_height
                  fighter2_weight
                  fighter2_reach
                  fighter2_record
                  result
                }
              }
            }
          }
        }`
      )
      .fetch();

    if (data?.data) {
      const events: Event[] = data?.data?.data?.items
        ?.reverse()
        ?.map((event) => {
          return {
            ...event,
            isExpired: isExpired(event.date),
            isPrimary: false,
            federation: {
              ...event.federation[0],
              logo: event.federation[0].logo[0].url,
            },
          };
        });

      const primaryIndex = events.findIndex(({ isExpired }) => !isExpired);

      if (primaryIndex >= 0) {
        events[primaryIndex].isPrimary = true;
      }

      dispatch(setEvents(events));
    } else {
      console.error(`ERROR: ${data?.code}-${data?.message}`);
    }

    dispatch(fetchEnd());
  };

export const getEvent =
  (id: string) =>
  async (dispatch: Dispatch<PayloadAction<Event | undefined>>) => {
    dispatch(fetchStart());

    const data: GraphQLElementResponse<EventDTO> = await prepr
      .graphqlQuery(
        `{
          data : Event(id: "${id}") {
            id : _id
            federation {
              id : _id
              name
              logo {
                url
              }
            }
            name
            date
            hour
            isMainEvent : is_main_event
            place
            ticketLink : ticket_link
            ppvLink : ppv_link
            fightCard: fight_card {
              ... on Fight {
                id : _id
                fighter1Name : fighter1_name
                fighter1Subname : fighter1_subname
                fighter1Age : fighter1_age
                fighter1Height : fighter1_height
                fighter1Weight : fighter1_weight
                fighter1Reach : fighter1_reach
                fighter1Record : fighter1_record
                fighter2Name : fighter2_name
                fighter2Subname : fighter2_subname
                fighter2Age : fighter2_age
                fighter2Height : fighter2_height
                fighter2Weight : fighter2_weight
                fighter2Reach : fighter2_reach
                fighter2Record : fighter2_record
                result
              }
            }
          }
        }`
      )
      .fetch();

    if (data?.data) {
      const eventData = data?.data.data;

      const event: Event = {
        ...eventData,
        federation: {
          ...eventData.federation[0],
          logo: eventData.federation[0].logo[0].url,
        },
        isExpired: isExpired(eventData.date),
      };

      dispatch(setActiveEvent(event));
    } else {
      console.error(`ERROR: ${data?.code}-${data?.message}`);
    }

    dispatch(fetchEnd());
  };

export const getArticles =
  () => async (dispatch: Dispatch<PayloadAction<Article[] | undefined>>) => {
    dispatch(fetchStart());

    const data: GraphQLArrayResponse<ArticleDTO> = await prepr
      .graphqlQuery(
        `{
          data: Articles ( limit : 20, sort : date_DESC ) {
            items {
              id: _id
              title
              image {
                url
              }
              link
              date
            }
          }
        }`
      )
      .fetch();

    if (data?.data) {
      const articles: Article[] = data?.data?.data?.items?.map((article) => {
        return {
          ...article,
          image: article.image[0].url,
        };
      });

      dispatch(setArticles(articles));
    } else {
      console.error(`ERROR: ${data?.code}-${data?.message}`);
    }

    dispatch(fetchEnd());
  };

export const selectFederations = (state: StoreType): Federation[] =>
  state.app.federations;

export const selectEvents = (state: StoreType): Event[] => state.app.events;

export const selectActiveEvent = (state: StoreType): Event | undefined =>
  state.app.activeEvent;

export const selectArticles = (state: StoreType): Article[] | undefined =>
  state.app.articles;

export const selectIsLoading = (state: StoreType): boolean =>
  state.app.isLoading;
