/* eslint-disable react-hooks/exhaustive-deps */
import "./homePage.scss";

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainBanner from "../../components/MainBanner/MainBanner";
import FederationsList from "../../components/FederationsList/FederationsList";
import Timeline from "../../components/Timeline/Timeline";
import MainEvents from "../../components/MainEvents/MainEvents";
import Calendar from "../../components/Calendar/Calendar";
import {
  getArticles,
  getEvents,
  getFederations,
  selectIsLoading,
} from "../../store/app";
import News from "../../components/news/News";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const HomePage = (): JSX.Element | null => {
  const eventRef = useRef(null);
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFederations());
    dispatch(getArticles());
    dispatch(getEvents());
  }, []);

  const scrollToMainEvents = (): void => {
    if (eventRef && eventRef.current) {
      (eventRef.current as any).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return !isLoading ? (
    <>
      <MainBanner />
      <FederationsList />
      <Timeline />
      <button onClick={scrollToMainEvents} className="main-events-btn">
        <div className="button-inner">
          <FontAwesomeIcon icon={faChevronDown} className="icon" />
          <span>Główne wydarzenia</span>
        </div>
      </button>
      <News />
      <div className="event-ref" ref={eventRef}></div>
      <MainEvents />
      <Calendar />
    </>
  ) : null;
};

export default HomePage;
