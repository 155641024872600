/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import EventBanner from "../../components/EventBanner/EventBanner";
import FightCard from "../../components/FightCard/FightCard";
import { getEvent, selectIsLoading } from "../../store/app";

const EventPage = (): JSX.Element | null => {
  const isLoading = useSelector(selectIsLoading);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getEvent(id));
    }
  }, [id]);

  return !isLoading ? (
    <>
      <EventBanner />
      <FightCard />
    </>
  ) : null;
};

export default EventPage;
