import "./footer.scss";
import logo from "../../assets/freakday_logo_grey.svg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTwitter } from "@fortawesome/fontawesome-free-brands";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container footer-inner">
        <Link to="">
          <img src={logo} alt="logo" className="logo" />
        </Link>
        <div className="social-container">
          <a
            className="social-link"
            href="https://twitter.com/freakdaypl"
            target="_blank"
            rel="noreferrer"
            aria-label="Twitter"
          >
            <FontAwesomeIcon className="icon" icon={faTwitter as IconProp} />
          </a>
          <a
            className="social-link"
            href="https://www.instagram.com/freakday.pl/"
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram"
          >
            <FontAwesomeIcon className="icon" icon={faInstagram as IconProp} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
